// Header dockning
let lastScrollTop = 0;
const body = document.body;
window.addEventListener('scroll',function(){
    let scrollTop = window.scrollY || document
        .documentElement.scrollTop;
    if (scrollTop > lastScrollTop){
        body.classList.add('hide');
        body.classList.remove('show');
    } else {
        body.classList.add('show');
        body.classList.remove('hide');
    }
    lastScrollTop = scrollTop;
});

// Mobile menu click
export default function mobileMenu() {
  const menuItem = document.querySelector('.page-header .wp-block-navigation-item__label');
  const html = document.querySelector('html');
  const mobileNav = document.querySelector('.wp-block-navigation__responsive-container');

  menuItem.addEventListener('click', () => {
    html.classList.remove('has-modal-open');
    mobileNav.classList.remove('is-menu-open', 'has-modal-open');
  }, false);

}

document.addEventListener('DOMContentLoaded', () => {
  mobileMenu();
})

